<template>
  <Gettasks></Gettasks>
</template>

<script>
import Gettasks from "../components/gettasks.vue";
export default {
  components: { Gettasks },
  name: "New",
  data() {
    return {};
  },
};
</script>

<style>
</style>