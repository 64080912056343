<template>
  <div>
    <v-sheet>
      <div v-if="tasks.data">
        <v-col v-for="(task, i) in tasks.data.articles" :key="i">
          <v-card
            :color="
              task.obj == 2
                ? 'success'
                : task.obj == 1
                ? 'info'
                : task.bool
                ? 'warning'
                : 'white'
            "
            elevation="5"
            class="my-4 mx-auto pa-6"
          >
            <v-row>
              <v-spacer></v-spacer>
              <v-btn @click="del(task.id)" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>

            {{ task.title }}
            <br />
            {{ task.content }}
            <br />
            Urgent : {{ task.bool }}
            <v-row>
              <v-col cols="12" md="8" class="mx-auto">
                <v-slider
                  v-model="task.obj"
                  :tick-labels="progress"
                  :max="2"
                  step="1"
                ></v-slider>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-alert v-if="task.users_permissions_user">
                {{ task.users_permissions_user.username }}</v-alert
              >

              <v-spacer></v-spacer>
              <v-btn @click="Submit(task.id, task.obj)"> Submit </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </div>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: ["jwt"],
  data() {
    return {
      response: "",
      error: "",
      progress: ["Not Started", "Working On It", "Finished"],
    };
  },
  //check to see if user is already signedin
  computed: {
    auth() {
      return this.$cookies.get("jwt")
        ? ` bearer ${this.$cookies.get("jwt")}`
        : this.jwt
        ? ` bearer ${this.jwt}`
        : "";
    },
    id() {
      return this.$cookies.get("id");
    },
  },
  methods: {
    Submit: function (id, obj) {
      this.$fitaxios({
        method: "put",
        url: `${id}`,
        data: { obj: obj },
        headers: {
          Authorization: `${this.auth}`,
        },
      }).then();
    },
    del: function (id) {
      this.$fitaxios({
        method: "delete",
        url: `${id}`,
        headers: {
          Authorization: `${this.auth}`,
        },
      })
        .then(() => {
          this.tasks.reload();
          // this.articles.splice(i, 1);
          // i != true;
        })
        .then(() => {
          // this.gt();
        })
        .catch((error) => ((this.login = true), (this.er = error)));
    },
  },
  chimera: {
    tasks() {
      return {
        method: "get",
        url: this.$fiturl + "users/" + this.id,
        // params: { _sort: "bool:Desc,title:ASC" },
        headers: {
          Authorization: `${this.auth}`,
        },
      };
    },
  },
};
</script>

 

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'sm-and-down')}
.v-slider__tick-label
  font-size: 14px
</style>
